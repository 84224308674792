.list-clients-container {
    padding: 2em;
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
}

.list-clients-container-item {
    width: 250px;
}