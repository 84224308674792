.chat-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(169, 255, 215);
    gap: 3px;
    padding: 10px 15px;
    overflow: auto;
    /* width: 100%; */
}

.message-row {
    display: flex;
    justify-content: flex-start;
}

.message-row.from-me {
    justify-content: flex-end;
}

.message-row.from-me .message-bubble {
    background-color: dodgerblue;
    color: white;
}

.message-item {
    display: flex;
    flex-direction: column;
    max-width: 80%;
}

.message-bubble {
    border: none;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    min-width: 100px;
    white-space: pre-line;
    box-shadow: 2px 2px 1px rgba(0,0,0,0.1);
}

.message-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: dodgerblue;
    font-weight: bold;
    cursor: pointer;
    transition: 0.15s;
    flex: 1;
    padding: 7px;
}

.message-button:hover {
    opacity: 0.9;
}

.message-button:active {
    opacity: 0.4;
}

.message-button-list {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -5px;
    border-top: 1px solid rgba(0,0,0,0.1);
}

.message-container-buttons {
    display: flex;
    margin: 4px 0px 0px 0px;
    gap: 4px;
}

.modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-message {
    width: auto;
    max-width: 300px;
    min-width: 100%;
}